.App {
  text-align: center;
  background-color: #1c1c1c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #f702d3;
  overflow: hidden;
}

.App img.logo {
  height: 3.5vh;
  padding: 10px;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 850ms;
}

.animate__animated.animate__fadeOut {
  --animate-duration: 850ms;
}