body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #1c1c1c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #f702d3;
  overflow: hidden;
}

.App img.logo {
  height: 3.5vh;
  padding: 10px;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 850ms;
}

.animate__animated.animate__fadeOut {
  --animate-duration: 850ms;
}
.home {
    width:auto;
    background-color: transparent;
    padding: 20px;
    margin-top: 12vh;
}

@media (max-width: 1366px)
{
        .home img.pfp {
                height: 40vh;
                max-height: 400px;
        }
}
@media (min-width: 1400px)
{
        .home img.pfp {
                height: 35vh;
                max-height: 550px;
        }
}

.home img.pfp {
    height: 35vh;
    max-height: 400px;
    border:5px solid #f702d3;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}



.proj {
    background-color: transparent;
    padding: 20px;
    margin-top: 6vh;
}


.info {
    background-color: transparent;
    padding: 20px;
    margin-top: 6vh;
}
