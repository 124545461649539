.home {
    width:auto;
    background-color: transparent;
    padding: 20px;
    margin-top: 12vh;
}

@media (max-width: 1366px)
{
        .home img.pfp {
                height: 40vh;
                max-height: 400px;
        }
}
@media (min-width: 1400px)
{
        .home img.pfp {
                height: 35vh;
                max-height: 550px;
        }
}

.home img.pfp {
    height: 35vh;
    max-height: 400px;
    border:5px solid #f702d3;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}


